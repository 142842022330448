/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";

import DemoNavbar from "../adminComponents/Navbars/DemoNavbar.jsx";
import Footer from "../adminComponents/Footer/Footer.jsx";
import Sidebar from "../adminComponents/Sidebar/Sidebar.jsx";
import FixedPlugin from '../adminComponents/FixedPlugin/FixedPlugin';

import CookieHandler from '../utils/cookieHandler.js';
import routes from "../routes";

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info"
    };
    this.mainPanel = React.createRef();
    const userData = CookieHandler.readCookie('userData');    
    if(!userData) window.location = '/';
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }
  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };
  render() {
    return (
      <div className="wrapper">
        <Sidebar
          type={window.location.hostname.split('.')[0]}
          {...this.props}
          routes={routes.filter(route=> route.onSideBar)}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref={this.mainPanel}>
          <DemoNavbar userData={this.props.userData} {...this.props} />
          <Switch>
            {routes.map((prop, key) => {
              if(prop.type != 'subMenu'){
                return (
                  <Route
                    exact
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                  />
                )}
              })
            }
          </Switch>
          <Footer fluid />
        </div>
        {/* <FixedPlugin
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          handleActiveClick={this.handleActiveClick}
          handleBgClick={this.handleBgClick}
        /> */}
      </div>
    );
  }
}

export default Dashboard;
