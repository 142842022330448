import React from "react";
import { MDBInputGroup } from "mdbreact";
import AppleIcon from "../../img/home/Appleicon.png";
import PlayStore from "../../img/home/Playstore.png";
import Asset1 from "../../img/home/new/Asset-1.png";
import PlayNum from "../../img/home/new/playstore.png";
import SubmitImg from "../../img/home/new/submit.png";
import { getByPlaceholderText } from "@testing-library/react";

const GetAppLink = () => {
  return (
   
    <div className="getapp-link "  >
      <form className="row" type="POST" action="https://getlink.okkji.in" target="_blank" >
      {/* <MDBInputGroup
          style={{ height: '65px',width: '100%', padding: 'none'}}
          container
          ClassName="mb-3 getapp-link-mobile"
          prepend= {<img src={PlayNum} width="100px" height="70px"/>}
          //prepend = "+91"
          append="Click Below"
          
          hint="Mobile Number"
          type="number"
          size="lg"
          name="mnum"
          id="mnum"
        /> */}
        {/* <div class="MDBInputGroup" style={{ height: '65px',width: '100%', padding: 'none'}}>
          <div ClassName="mb-3 getapp-link-mobile">
            <img src={PlayNum} width="100px" height="70px"/>
            <input ClassName="mb-3 getapp-link-mobile" type="text" name="number"></input>
      
         <button type="submit"><img src={SubmitImg} className="img-fluid appIcon"  /></button></div> </div> */}
        <div class="input-group mb-3 getapp-link-mobile" style={{ height: '65px',width: '100%', padding: 'none'}}>
        <div class="input-group-prepend" style={{ height: '65px', padding: 'none'}}>
        <span class="input-group-text" id="" ><img src={PlayNum} width="100px" height="70px"/></span>
        </div>
         <input type="tel" name="mobile" style={{ backgroundColor: '#fff' }} class="form-control" pattern="^\d{10}$" requiredplaceholder="Mob. Number" aria-label="Mobile Number" required  ></input> 

        <div class="input-group-append" style={{ backgroundColor: '#259d70', borderRadius: '6%' }} >
        <button style={{ height: '35px', padding: 'none',  border: 'none', radius: '2%',color: 'white', backgroundColor: '#259d70'}} type="submit"><b>Get App Link</b></button>
        </div>
        </div>
      </form>
      <div className="appIcon-row" >
        <img src={AppleIcon} className="img-fluid appIcon" />
        <img src={PlayStore} className="img-fluid appIcon"/>
      </div>
    </div>
  );
};

export default GetAppLink;
