import React, { Component } from 'react';
import KiranaStoreEditBody from '../kiranastoreedit/KiranaStoreEditBody';

export default class KiranaStoreEdit extends Component {
  render() {
    return (
      <div>
        <KiranaStoreEditBody />
      </div>
    )
  }
}
