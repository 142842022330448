import React, { Component } from 'react'
import BlogPageBody from '../blogpage1/BlogPageBody';

export default class BlogPage1 extends Component {
  render() {
    return (
      <div>
        <BlogPageBody />
      </div>
    )
  }
}
