import React, { Component } from 'react'
//import smallimg from '../../img/blog/smallimg.png';
//import smallimg2 from '../../img/blog/smallimg2.jpg';
import blog3 from '../../img/blog/blog3.jpg';
import blog4 from '../../img/blog/blog4.jpg';
import blog5 from '../../img/blog/blog5.jpg';
import blog6 from '../../img/blog/blog6.jpg';
import blog7 from '../../img/blog/blog7.jpg';



//import blog1 from '../../img/blogpage/blog1.png';
//import blog2 from '../../img/blogpage/blog2.png';


import { MDBIcon } from "mdbreact";

export default class OkkjiBlogPost extends Component {
  render() {
    return (
      <div>
                <div className="row">
          <div className="col-md-6">
          <div className="blog-column">
            <figure classname="blog-figure">
              <img src={blog3} alt=""  className="img-fluid" />
              <figcaption>
              <h3>Aakash Kumar | 25 Nov 2019 | Food &amp; LifeStyle</h3>
              </figcaption>
            </figure>
            <h2>#Why Neighbourhood Kirana Survive in the Wave of E-Commerce</h2>
             <p className="" id="collapseContent">When the e-commerce sector is growing at a rapid rate, Kirana shops are firm static in their places. When giant corporate houses entering in eCommerce business and ready to temper the ...</p>
            <a href="/user-blog" className="blog-button">Read More</a>
            <MDBIcon icon="share-alt" className="float-right  mdb-icon" /><span className="float-right number-flower">07</span>
            <MDBIcon far icon="heart" className="float-right  mdb-icon" /><span className="float-right number-flower">49</span>
          </div>
          <div className="bdr-bottom"></div>
          </div>

          <div className="col-md-6">
            <div className="blog-column">
          <figure classname="blog-figure">
              <img src={blog4} alt=""  className="img-fluid" />
              <figcaption>
              <h3>Aakash Kumar | 25 Nov 2019 | Food &amp; LifeStyle</h3>
              </figcaption>
            </figure>
            <h2>#What is the Future of Online Grocery in India<br /><br /></h2>
            <p className="" id="collapseContent">When the e-commerce sector is growing at a rapid rate, Kirana shops are firm static in their places. When giant corporate houses entering in eCommerce business and ready to temper the ...</p>
  <a href="/user-blog" className="blog-button">Read More</a>
  <MDBIcon icon="share-alt" className="float-right mdb-icon" /><span className="float-right number-flower">07</span>
  <MDBIcon far icon="heart" className="float-right mdb-icon" /><span className="float-right number-flower">49</span>
  </div>
  <div className="bdr-bottom"></div>
          </div>

          <div className="col-md-6">
            <div className="blog-column">
          <figure classname="blog-figure">
              <img src={blog6} alt=""  className="img-fluid" />
              <figcaption>
              <h3>Aakash Kumar | 25 Nov 2019 | Food &amp; LifeStyle</h3>
              </figcaption>
            </figure>
            <h2>#Why Kirana stores are Evergreen in Indian Society?</h2>
            <p className="" id="collapseContent">For about insofar as individuals have existed, they have been sharing, trading, selling, and expanding assets.  Society Kirana stores are Evergreen from the very beginning of society. If we follow the ...</p>
  <a href="/user-blog-3" className="blog-button">Read More</a>
  <MDBIcon icon="share-alt" className="float-right mdb-icon" /><span className="float-right number-flower">07</span>
  <MDBIcon far icon="heart" className="float-right mdb-icon" /><span className="float-right number-flower">49</span>
  </div>
  <div className="bdr-bottom"></div>
          </div>
          <div className="col-md-6">
            <div className="blog-column">
          <figure classname="blog-figure">
              <img src={blog7} alt=""  className="img-fluid" />
              <figcaption>
              <h3>Aakash Kumar | 25 Nov 2019 | Food &amp; LifeStyle</h3>
              </figcaption>
            </figure>
            <h2>#Why Kirana Store is Loosing its Charm</h2>
            <p className="" id="collapseContent">When the e-commerce sector is growing at a rapid rate, Kirana shops are firm static in their places. When giant corporate houses entering in eCommerce business and ready to temper the ...</p>
  <a href="/user-blog" className="blog-button">Read More</a>
  <MDBIcon icon="share-alt" className="float-right mdb-icon" /><span className="float-right number-flower">07</span>
  <MDBIcon far icon="heart" className="float-right mdb-icon" /><span className="float-right number-flower">49</span>
  </div>
  <div className="bdr-bottom"></div>
          </div>
          <div className="col-md-6">
            <div className="blog-column">
          <figure classname="blog-figure">
              <img src={blog5} alt=""  className="img-fluid" />
              <figcaption>
              <h3>Aakash Kumar | 25 Nov 2019 | Food &amp; LifeStyle</h3>
              </figcaption>
            </figure>
            <h2>#Why India Needs Online Kirana Stores</h2>
            <p className="" id="collapseContent">When the e-commerce sector is growing at a rapid rate, Kirana shops are firm static in their places. When giant corporate houses entering in eCommerce business and ready to temper the ...</p>
  <a href="/user-blog" className="blog-button">Read More</a>
  <MDBIcon icon="share-alt" className="float-right mdb-icon" /><span className="float-right number-flower">07</span>
  <MDBIcon far icon="heart" className="float-right mdb-icon" /><span className="float-right number-flower">49</span>
  </div>
  <div className="bdr-bottom"></div>
          </div>
          <div className="col-md-6">
            <div className="blog-column">
          <figure classname="blog-figure">
              <img src={blog6} alt=""  className="img-fluid" />
              <figcaption>
              <h3>Aakash Kumar | 25 Nov 2019 | Food &amp; LifeStyle</h3>
              </figcaption>
            </figure>
            <h2>#Why Kirana Store is an Integral Part of Indian Society</h2>
            <p className="" id="collapseContent">When the e-commerce sector is growing at a rapid rate, Kirana shops are firm static in their places. When giant corporate houses entering in eCommerce business and ready to temper the ...</p>
  <a href="/user-blog" className="blog-button">Read More</a>
  <MDBIcon icon="share-alt" className="float-right mdb-icon" /><span className="float-right number-flower">07</span>
  <MDBIcon far icon="heart" className="float-right mdb-icon" /><span className="float-right number-flower">49</span>
  </div>
  <div className="bdr-bottom"></div>
          </div>


        </div>
<div className="row more-post-button">
<button className="text-center text-primary">More Post</button>          

</div>

      </div>
    )
  }
}
