import React, { Component } from 'react';
class StoreSettelment extends Component {

  render() { 
    return ( 
      <div className='content'>
        <span>StoreSettelment</span>
      </div>
     );
  }
}
 
export default StoreSettelment;